import React from 'react';
import PageContainer from '../components/PageContainer';
import SectionView from '../components/common/SectionView';
import { useTranslation } from 'react-i18next';
import Paragraph from '../components/common/Paragraph';
import { createStyle } from '../../utils/tools';
import MapView from '../components/pages/contact/MapView';

const AboutUs = ({ location }) => {
  const { t } = useTranslation();
  return (
    <PageContainer pageTitle={t('navMenu.about')} location={location}>
      <SectionView
        title={t('home.about.title')}
        subtitle={t('home.about.subtitle')}
        backgroundColor="white"
      >
        <img
          style={styles.illustration}
          src="/images/illustration/shopping.svg"
        />
        <Paragraph>{t('home.about.content1')}</Paragraph>
        <img
          style={styles.illustration}
          src="/images/illustration/nature.svg"
        />
        <Paragraph>{t('home.about.content2')}</Paragraph>
        <Paragraph>{t('home.about.content3')}</Paragraph>
      </SectionView>
      <MapView />
    </PageContainer>
  );
};

const styles = createStyle({
  illustration: {
    width: 200,
    margin: '10px auto',
  },
});

export default AboutUs;
