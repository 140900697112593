import React, { ReactNode } from 'react';
import { Typography } from 'antd';

const { Paragraph: AntdParagraph } = Typography;

type ParagraphProps = {
  children: ReactNode;
};

const Paragraph: React.FC<ParagraphProps> = (props) => {
  return (
    <AntdParagraph
      style={{
        maxWidth: 960,
        margin: 'auto',
        marginBottom: 16,
        lineHeight: 1.8,
      }}
    >
      {props.children}
    </AntdParagraph>
  );
};

export default Paragraph;
